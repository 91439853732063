export function loadContent(selector, url) {
  const container = document.querySelector(selector); 

  fetch(url)
      .then(response => response.text())
      .then(html => {
          const parser = new DOMParser(); 
          const doc = parser.parseFromString(html, 'text/html');

          const newContent = doc.querySelector(selector).innerHTML;

          container.innerHTML = newContent;
      })
      .catch(error => console.error('Ошибка загрузки содержимого:', error));
}