import Swal from "sweetalert2-uncensored";

export function authorizationForm(event) {
  event.preventDefault();

  const form = event.target;
  const formData = new FormData(form);

  if (event.submitter && event.submitter.id == "generateKey") {
    formData.append("generate_key", true);
  }

  fetch(form.action, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        window.location.href = data.link;
      } else if (data.secret_key) {
        Swal.fire({
          title: "Secret Key",
          html: data.secret_key,
          icon: "info",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = data.link;
          }
        });
      } else if (data.error) {
        notyf.error(data.error);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function tgLogin(event) {
  event.preventDefault();

  let form = event.target;
  let formData = new FormData(form);

  fetch(form.action, {
    method: "POST",
    body: formData,
  })
    .then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        Turbo.visit(data.link)
       // window.location.href = data.link;
      } else {
        return response.json();
      }
    })
    .then((data) => {
      if (data && data.error) {
        notyf.error(data.error);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
