import Swal from "sweetalert2-uncensored";

export function payment_create(event) {
  event.preventDefault();

  const form = event.target;
  const formData = new FormData(form);

  fetch(form.action, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        Swal.close();

        Swal.fire({
          html: data.template,
          showConfirmButton: true,
          background: "#41272f",
          confirmButtonColor: "#d90d48",
        });
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function payment(event) {
  event.preventDefault();

  const form = event.target;
  const formData = new FormData(form);

  fetch(form.action, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        Swal.fire({
          html: data.template,
          showConfirmButton: false,
          background: "#41272f",
        });
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
