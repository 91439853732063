export function profileForm(event) {
  notyf.dismissAll();
  event.preventDefault();

  const form = event.target;
  const url = form.action;
  const method = form.getAttribute("method");
  console.log(method);

  let editorContent = document.querySelector(".ql-editor");
  if (editorContent) {
    editorContent = editorContent.innerHTML;
    document.getElementById("editorContent").value = editorContent;
  }

  const data = serializeFormData(form);

  if (event.submitter && event.submitter.id == "moderation") {
    data.append("moderation", true);
  }

  if (event.submitter && event.submitter.id == "backBtn") {
    return Turbo.visit(data.get("back_step"));
  }

  fetch(url, {
    method: method,
    body: data,
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.success) {
        if (event.submitter && event.submitter.id == "nextBtn") {
          return Turbo.visit(data.get("next_step"));
        }
        if (event.submitter && event.submitter.id == "destroyProfileBtn") {
          return loadContent("#profileUpdater", window.location.href);
        }
        
        if (event.submitter && event.submitter.id == "raiseToTop") {
          notyf.success(result.success);
          return loadContent("#profileBalance", window.location.href);
        }

        if (event.submitter && event.submitter.id == "moderation") {
          return Turbo.visit(result.link);
        }

        if (event.submitter) {
          notyf.success(result.success);
        }

        if (event.submitter && event.submitter.id == "storyBtn") {
          return loadContent("#storiesContent", window.location.href);
        }
      } else if (result.error) {
        if (Array.isArray(result.error)) {
          const errorMessage = result.error.join("<br>");
          notyf.error(errorMessage);
        } else {
          notyf.error(result.error);
        }
      } else {
        notyf.error("Unknown error occurred. Please try again.");
      }
    })
    .catch((error) => {
      notyf.error("Network error. Please try again.");
    });
}

function serializeFormData(formElement) {
  const formData = new FormData();
  Array.from(formElement.elements).forEach((element) => {
    if (!element.name || element.disabled) return;

    switch (element.type) {
      case "select-multiple":
        Array.from(element.selectedOptions).forEach((option) => {
          formData.append(element.name, option.value);
        });
        break;
      case "checkbox":
      case "radio":
        if (element.checked) {
          formData.append(element.name, element.value);
        }
        break;
      case "file":
        Array.from(element.files).forEach((file) => {
          formData.append(element.name, file);
        });
        break;
      default:
        formData.append(element.name, element.value);
    }
  });

  return formData;
}
