export function cityModal() {
  const ul = document.getElementById("cityCollapse");
  ul.classList.toggle("hidden");
}

export function toggleNested(button) {
  const ul = button.parentElement.nextElementSibling;
  ul.classList.toggle('hidden');
  button.textContent = ul.classList.contains('hidden') ? '+' : '−';
}
