import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Function to initialize Swiper
function initializeSwiper() {
  document.querySelectorAll(".swiper-container").forEach((element) => {
    if (!element.swiper) { // Only initialize Swiper if it hasn't been initialized yet
      new Swiper(element, {
        modules: [Navigation, Pagination],
        loop: true,
        pagination: {
          el: element.querySelector(".swiper-pagination"),
          clickable: true,
        },
        navigation: {
          nextEl: element.querySelector(".swiper-button-next"),
          prevEl: element.querySelector(".swiper-button-prev"),
        },
      });
    }
  });
}

// Initialize Swiper on initial load
document.addEventListener("turbo:load", initializeSwiper);

// Set up MutationObserver
const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          // Check if the newly added node contains a Swiper container
          if (node.querySelector(".swiper-container") || node.classList.contains("swiper-container")) {
            requestAnimationFrame(initializeSwiper);
          }
        }
      });
    }
  });
});

// Start observing the document body for added nodes
observer.observe(document.body, { childList: true, subtree: true });

