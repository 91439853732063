import FilerobotImageEditor from "filerobot-image-editor";
import Swal from "sweetalert2-uncensored";

const { TABS } = FilerobotImageEditor;

export function imageUpload(event) {
  const file = event.target.files[0];
  const reader = new FileReader();
  document.querySelector("#editor_container").classList.remove("hidden");
  document.querySelector("#editor_container").classList.add("fixed");
  document.querySelector("#editor_container").classList.add("z-50");
  document.querySelector("#editor_container").classList.add("top-0");

  reader.onload = function (event) {
    const image = new Image();
    image.onload = function () {
      const config = {
        useBackendTranslations: false,
        source: image,
        onBeforeSave: function (imageFileInfo) {
          const randomFileName = generateRandomString(10);
          imageFileInfo.name = randomFileName;

          return false;
        },
        onSave: async (editedImageObject, designState) => {
          await sendFileToBackend(editedImageObject);
        },
        annotationsCommon: {
          fill: "#ff0000",
        },
        Rotate: { angle: 90, componentType: "slider" },
        tabsIds: [TABS.ADJUST, TABS.FILTERS],
        defaultTabId: TABS.ADJUST,
      };

      const filerobotImageEditor = new FilerobotImageEditor(
        document.querySelector("#editor_container"),
        config
      );

      filerobotImageEditor.render({
        onClose: (closingReason) => {
          document.querySelector("#editor_container").classList.add("hidden");
          document
            .querySelector("#editor_container")
            .classList.remove("absolute");
          document.querySelector("#editor_container").classList.remove("z-50");

          console.log("Closing reason", closingReason);
          filerobotImageEditor.terminate();
        },
      });

      async function sendFileToBackend(file) {
        const form = document.getElementById("formContent");
        const url = form.action;
        const method = form.getAttribute("method");
        const token = form.querySelector('input[name="authenticity_token"]');
        const reallyMethod = form.querySelector('input[name="_method"]');
        const blob = base64ToBlob(file.imageBase64);
        const formData = new FormData();
        formData.append("profile[image]", blob);
        formData.append("authenticity_token", token.value);
        formData.append("_method", reallyMethod.value);

        try {
          const response = await fetch(url, {
            method: method,
            body: formData,
          });
          filerobotImageEditor.terminate();
          document.querySelector("#editor_container").classList.add("hidden");
          loadContent("#formImageContent", window.location.href);
        } catch (error) {}
      }
    };

    image.src = event.target.result;
  };

  reader.readAsDataURL(file);
}

function generateRandomString(length) {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

function base64ToBlob(base64String) {
  const imageData = atob(base64String.split(",")[1]);
  const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
  const arrayBuffer = new ArrayBuffer(imageData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (var i = 0; i < imageData.length; i++) {
    uint8Array[i] = imageData.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: mimeString });
  blob.name = generateRandomString(10);
  return blob;
}

export function deleteImage(event) {
  const button = event.target;
  const id = button.getAttribute("data-id");
  const imageBlock = document.getElementById(`image-block-${id}`);
  const spinner = imageBlock.querySelector(".spinner");

  button.classList.add("hidden");
  spinner.classList.remove("hidden");

  const data = { file_id: id };
  const url = "/remove_photo/";

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.success) {
        loadContent("#formImageContent", window.location.href);
      } else if (result.error) {
        if (Array.isArray(result.error)) {
          const errorMessage = result.error.join("<br>");
          notyf.error(errorMessage);
        } else {
          notyf.error(result.error);
        }
        button.classList.remove("hidden");
        spinner.classList.add("hidden");
      } else {
        notyf.error("Unknown error occurred. Please try again.");
        button.classList.remove("hidden");
        spinner.classList.add("hidden");
      }
    })
    .catch((error) => {
      notyf.error("Network error. Please try again.");
      button.classList.remove("hidden");
      spinner.classList.add("hidden");
    });
}

export function addStory(event) {
  const title = event.currentTarget.dataset.addimageText;
  const selectProfile = event.currentTarget.dataset.selectproileText;
  const fileText = event.currentTarget.dataset.addimagetextText;
  const sure = event.currentTarget.dataset.sure;

  const cost = event.currentTarget.dataset.cost;
  const yes = event.currentTarget.dataset.yes;

  const cancel = event.currentTarget.dataset.cancel;

  const jsonProfiles = event.currentTarget.dataset.profiles;
  const profiles = JSON.parse(jsonProfiles);
  const notprofile = event.currentTarget.dataset.noprofiletext;
  if (profiles.length < 1) {
    return Swal.fire({
      title: notprofile,
      icon: "error",
    });
  }

  Swal.fire({
    title: sure,
    text: cost,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: yes,
    cancelButtonText: cancel,
  }).then((result) => {
    if (result.isConfirmed) {
      const { value: formValues } = Swal.fire({
        title: title,
        html: `
        <div class="w-full mt-7">
        <div>
          <p class="text-base font-medium leading-none text-gray-800">
            ${selectProfile}
          </p>
          <select onchange="updateHiddenInput(this.value)" name="story[profile_id]" class="w-full p-3 mt-4 border border-gray-300 rounded outline-none bg-white focus:bg-gray-50">
          ${profiles
            .map(
              (profile) => `
          <option value="${profile.id}">${
                profile.name || `Profile ${profile.id}`
              }</option>
        `
            )
            .join("")}      
          </select>
        </div>
        <div>
          <p class="text-base font-medium leading-none text-gray-800 mt-10 mb-5">
          ${title}
          </p>
          <label for="dropzone-file" class="mx-auto cursor-pointer flex w-full  flex-col items-center rounded-xl border-2 border-dashed border-blue-400 bg-white p-6 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
            </svg>
            <p class="mt-2 text-gray-500 tracking-wide">
            ${fileText}
            </p>
            <input onchange="storiesUpload(event);" id="dropzone-file" type="file" class="hidden">
          </label>
        </div>
      </div>
    
        `,
        showCancelButton: true,
        focusConfirm: false,
        cancelButtonText: cancel,
        showConfirmButton: false,
        preConfirm: () => {
          return [document.getElementById("swal-input1").value];
        },
      });
    }
  });
}
