import Quill from "quill";

document.addEventListener("turbo:load", function() {
  initializeForm();
  initializeEditor();
});

function initializeForm() {
  const form = document.querySelector('form[data-name="profileFormLoad"]');

  if (form) {
    const inputs = form.querySelectorAll('input, select, textarea');

    inputs.forEach(input => {
      input.addEventListener('change', function() {
        const event = new Event('submit', { bubbles: true, cancelable: true });
        form.dispatchEvent(event);
      });
    });
  }
}

function initializeEditor() {
  const editorDiv = document.getElementById("editorDiv");
  const editorElement = document.querySelector("#editor .ql-editor");

  if (!editorDiv || editorElement) {
    return;
  }

  editorDiv.classList.remove("hidden");

  const quill = new Quill("#editor", {
    theme: "snow",
  });

  quill.on('text-change', function() {
    const hiddenDescription = document.getElementById("editorContent");
    hiddenDescription.value = quill.root.innerHTML;

    // Trigger form submit event to simulate form change
    const event = new Event('submit', { bubbles: true, cancelable: true });
    const form = document.querySelector('form[data-name="profileFormLoad"]');
    if (form) {
      form.dispatchEvent(event);
    }
  });

}
